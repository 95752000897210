import { useState } from 'react';

import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { motion } from 'framer-motion';

import styles from '../../pages/Clarifications/components/ImagesModal/ImageModal.module.css';

import { Box, Flex, Image, Modal } from '@mantine/core';

export default function ImageGallery({
  markdownImages,
  dimmed = false
}: {
  dimmed?: boolean;
  markdownImages: string[];
}) {
  const TRANSITION_DURATION = 300;
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const [embla, setEmbla] = useState<Embla | null>(null);

  useAnimationOffsetEffect(embla, TRANSITION_DURATION);
  return (
    <Box className={styles.grid}>
      {markdownImages.map((markDownImage, index) => (
        <Flex className={styles.thumbnailWrapper} key={index}>
          <motion.img
            src={markDownImage}
            alt="Question"
            className={`${styles.thumbnail} opacity-0 transition-all duration-300 ease-in-out`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onClick={() => {
              setSelectedImage(index);
              setShowModal(!showModal);
            }}
            onLoad={(e) => {
              e.currentTarget.style.opacity = '1';
            }}
          />
        </Flex>
      ))}
      {showModal ? (
        <Modal.Root
          opened={showModal}
          fullScreen
          onClose={() => setShowModal(false)}
        >
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header
              bg="#000000"
              styles={{
                header: {
                  border: 'none'
                }
              }}
            >
              <Modal.CloseButton className={styles.closeButton} />
            </Modal.Header>
            <Modal.Body p={0}>
              <Flex style={{ height: 'calc(100vh - 60px)' }}>
                <Carousel
                  classNames={styles}
                  slideSize="100%"
                  withIndicators
                  height="100%"
                  style={{ flex: 1 }}
                  slideGap="xl"
                  getEmblaApi={setEmbla}
                  onLoad={() => {
                    embla?.scrollTo(selectedImage);
                  }}
                >
                  {markdownImages.map((markDownImage, index) => (
                    <Carousel.Slide
                      key={index}
                      className="flex items-center justify-center"
                    >
                      <Image
                        className="w-[700px]"
                        src={markDownImage}
                        alt="Question"
                      />
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </Flex>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      ) : null}
    </Box>
  );
}
