import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from 'src/context/Auth';
import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import { RFQ } from './props';

const get_rfqs_url = `${API_URL}/api/rfqs?status=draft`;

const useLogic = ({ setIsRfqsEmpty }: { setIsRfqsEmpty: Function }) => {
  const { isLoading: authLoading } = useContext(AuthContext);
  const [rfqs, setRfqs] = useState<RFQ[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchRfqs = async () => {
    try {
      const response = await fetchWithAuth(get_rfqs_url, {
        method: 'GET'
      });

      const data = await response.json();
      if (data.length === 0) {
        setIsRfqsEmpty(true);
      }
      setRfqs(data);
    } finally {
      setLoading(false);
    }
  };

  const redirectToRfq = (rfqId: number) => {
    navigate(`/new-rfq?rfq_id=${rfqId}`);
  };

  useEffect(() => {
    fetchRfqs();
  }, []);

  return {
    isLoading: authLoading || loading,
    rfqs,
    redirectToRfq
  };
};

export default useLogic;
