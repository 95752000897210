import { getInitials } from '../../utils/formatters';

const KELLY_COLORS_HEX = [
  0xe6c98b, // Desaturated Yellow
  0x9b8599, // Desaturated Purple
  0xe6a585, // Desaturated Orange
  0xc2d1e0, // Very Light Desaturated Blue
  0xd19898, // Desaturated Red
  0xd6c4a9, // Desaturated Grayish Yellow
  0xa69e99 // Medium Warm Gray
];

interface UserAvatarProps {
  displayName: string;
  avatar?: string | null;
  size?: 'normal' | 'large';
}

const UserAvatar = ({
  displayName,
  avatar,
  size = 'normal'
}: UserAvatarProps) => {
  if (avatar) {
    return (
      <img
        src={avatar}
        alt={displayName}
        className="size-full object-cover rounded-md"
      />
    );
  }

  // Use a hash of the username to pick a consistent color
  const hash = displayName
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const color = KELLY_COLORS_HEX[hash % KELLY_COLORS_HEX.length];

  return (
    <div
      className={`size-full rounded-md flex items-center justify-center text-white font-500 ${
        size === 'large' ? 'text-[44px]' : ''
      }`}
      style={{ backgroundColor: `#${color.toString(16)}` }}
    >
      {getInitials(displayName)}
    </div>
  );
};

export default UserAvatar;
