import { ComponentProps } from 'react';
import ReactMarkdown from 'react-markdown';

import remarkBreaks from 'remark-breaks';

import { mdVariableSubstitution } from '../../utils/mdVariableSubstitution';
import ImageGallery from '../ImageGallery/ImageGallery';

interface MarkdownProps extends ComponentProps<typeof ReactMarkdown> {
  replace?: { [key: string]: string };
  children?: string;
  galleryProps?: {
    [key: string]: any;
  };
}

/**
 * This is a wrapper component that extends the ReactMarkdown component and extracts all the images within the markdown and attaches a library at the end of the markdown to display them. It also allows for string interpolation in the markdown strings.
 *
 * @param replace - An optional object where each key-value pair represents a variable and its replacement. I.e. In markding "{{producName}}" should be indicated as { productName: "Product Name"}
 * @param children - The markdown string to process. It can contain variables.
 * @param ...props - The props to pass to the react-markdown component as options.
 * @returns The processed markdown string with the variables replaced.
 */

function processMarkdown(
  markdown: string,
  replace: Record<string, string> = {}
): { markdown: string; imageUrls: string[] } {
  const imageUrls: string[] = [];
  const processedMarkdown = markdown
    .replace(/!\[.*?\]\((.*?)\)/g, (_, url) => {
      imageUrls.push(url);
      return ''; // Remove image from markdown
    })
    .replace(/\n$/, ''); // Remove final newline

  const substitutedMarkdown = mdVariableSubstitution(
    processedMarkdown,
    replace
  );

  return { markdown: substitutedMarkdown, imageUrls };
}

export default function CustomMarkdownWithGallery({
  replace,
  children,
  galleryProps,
  ...props
}: MarkdownProps) {
  if (!children) {
    return null;
  }

  const { markdown, imageUrls } = processMarkdown(children, replace);

  return (
    <>
      <ReactMarkdown
        components={{ img: () => null }}
        {...props}
        remarkPlugins={[remarkBreaks]}
      >
        {markdown}
      </ReactMarkdown>

      {imageUrls?.length > 0 ? (
        <ImageGallery markdownImages={imageUrls} {...galleryProps} />
      ) : (
        ''
      )}
    </>
  );
}
