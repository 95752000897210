import { useContext, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AuthContext from '../../context/Auth';
import useOrganizationStore from '../../hooks/useOrganizationStore';
import { Organization } from '../../types/Organization';
import { getInitials } from '../../utils/formatters';

export const OrganizationAvatar = ({
  organization
}: {
  organization: Organization | null;
}) => {
  if (!organization) return null;

  if (organization.avatar) {
    return (
      <img
        src={organization.avatar}
        alt={organization.name}
        className="size-full object-cover rounded-md"
      />
    );
  }

  return (
    <div className="size-full rounded-md bg-black flex items-center justify-center text-white font-500">
      {getInitials(organization.name)}
    </div>
  );
};

interface OrganizationSelectorProps {
  isCollapsed: boolean;
}

const OrganizationSelector = ({ isCollapsed }: OrganizationSelectorProps) => {
  const { userInfo } = useContext(AuthContext);
  const [isOrgTooltipOpen, setIsOrgTooltipOpen] = useState(false);
  const [isOrgMenuOpen, setIsOrgMenuOpen] = useState(false);
  const orgMenuRef = useRef<HTMLDivElement>(null);
  const { selectedOrganization, setSelectedOrganization } =
    useOrganizationStore();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        orgMenuRef.current &&
        !orgMenuRef.current.contains(event.target as Node)
      ) {
        setIsOrgMenuOpen(false);
      }
    };

    if (isOrgMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOrgMenuOpen]);

  return (
    <div
      ref={orgMenuRef}
      className="relative w-full h-10 flex gap-1 items-center cursor-pointer hover:bg-gray-4 rounded-lg px-0.5"
      onClick={(e) => {
        e.stopPropagation();
        setIsOrgMenuOpen(!isOrgMenuOpen);
      }}
      {...(!isOrgMenuOpen && {
        'data-tooltip-id': 'organization',
        'data-tooltip-content': selectedOrganization?.name
      })}
      data-testid="organization-selector"
      onMouseEnter={() => setIsOrgTooltipOpen(true)}
      onMouseLeave={() => setIsOrgTooltipOpen(false)}
    >
      <div
        className={`flex items-center w-full ${
          isCollapsed ? 'gap-0' : 'gap-2'
        }`}
      >
        <div className="flex items-center justify-center size-8 shrink-0">
          <div className="size-8 rounded-md overflow-hidden">
            <OrganizationAvatar organization={selectedOrganization} />
          </div>
        </div>
        <div
          className="w-full flex items-center gap-0 min-w-0"
          data-testid="org-menu-header"
        >
          {!isCollapsed && (
            <span className="truncate text-black font-500">
              {selectedOrganization?.name}
            </span>
          )}
          {userInfo?.organizations && userInfo.organizations.length > 1 && (
            <KeyboardArrowDownIcon
              className={`transition-transform ml-auto ${
                isOrgMenuOpen ? 'rotate-180' : ''
              } shrink-0`}
              sx={{ fontSize: 20 }}
            />
          )}
        </div>
      </div>

      {isOrgMenuOpen &&
        userInfo?.organizations &&
        userInfo.organizations.length > 1 && (
          <div
            className="absolute top-full left-0 w-[300px] mt-1 bg-white rounded-lg shadow-lg border border-gray-200 z-50"
            onClick={(e) => e.stopPropagation()}
            data-testid="org-menu"
          >
            {userInfo.organizations.map((org, index) => (
              <div
                key={org.id}
                className={`flex items-center gap-2 px-2 py-2 hover:bg-p-cavela-blue hover:text-white cursor-pointer transition-colors
                  ${index === 0 ? 'rounded-t-lg' : ''} 
                  ${
                    index === (userInfo?.organizations?.length ?? 0) - 1
                      ? 'rounded-b-lg'
                      : ''
                  }`}
                onClick={() => {
                  setSelectedOrganization(org);
                  setIsOrgMenuOpen(false);
                  setIsOrgTooltipOpen(false);
                  window.location.href = '/';
                }}
              >
                <div className="flex items-center justify-center size-8 shrink-0">
                  <div className="size-8 rounded-md overflow-hidden">
                    <OrganizationAvatar organization={org} />
                  </div>
                </div>
                <span className="truncate font-500">{org.name}</span>
              </div>
            ))}
          </div>
        )}

      <div className="z-50">
        {isCollapsed && <Tooltip id="organization" isOpen={isOrgTooltipOpen} />}
      </div>
    </div>
  );
};

export default OrganizationSelector;
