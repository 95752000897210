import { useEffect, useRef, useState } from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

import { AnimatePresence, motion } from 'framer-motion';

interface ToastProps {
  message: string;
  type: 'error' | 'success';
  needRefresh?: boolean;
  setError?: Function;
}

const Toast = ({
  message,
  type,
  needRefresh = false,
  setError
}: ToastProps) => {
  const boxVissibleTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const errorTextTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (message == '') return;
    if (boxVissibleTimeoutId.current)
      clearTimeout(boxVissibleTimeoutId.current);
    if (errorTextTimeoutId.current) clearTimeout(errorTextTimeoutId.current);
    setIsVisible(true);
    const boxVissibleTimeoutTimeout = setTimeout(() => {
      setIsVisible(false);
    }, 8000);
    const errorTextTimeoutTimeout = setTimeout(() => {
      setError && setError('');
    }, 9000);
    boxVissibleTimeoutId.current = boxVissibleTimeoutTimeout;
    errorTextTimeoutId.current = errorTextTimeoutTimeout;

    return () => {
      clearTimeout(boxVissibleTimeoutTimeout);
      clearTimeout(errorTextTimeoutTimeout);
      setError && setError('');
    };
  }, [message]);

  return (
    <AnimatePresence>
      {isVisible && message.length !== 0 ? (
        <motion.div
          /* onClick={closeToast} */
          className="fixed z-50 left-0 bottom-0 p-2 w-screen"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ type: 'spring', stiffness: 100, damping: 20 }}
        >
          <div
            className="w-full h-9 flex items-center gap-1 px-2 rounded-md overflow-hidden shadow-xl"
            style={{
              backgroundColor: type === 'error' ? '#FEF3F3' : '#DAF8E6',
              color: type === 'error' ? '#E10E0E' : '#1A8245'
            }}
          >
            <div className="flex items-center justify-center">
              {type === 'error' ? (
                <DangerousRoundedIcon
                  style={{
                    fontSize: '20px',
                    color: '#E10E0E'
                  }}
                />
              ) : (
                <CheckCircleRoundedIcon
                  style={{
                    fontSize: '20px',
                    color: '#1A8245'
                  }}
                />
              )}
            </div>
            <div>
              <span className="text-sm font-[500]">{message} </span>
              {needRefresh ? (
                <span
                  className="text-p-cavela-blue cursor-pointer"
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </span>
              ) : null}
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default Toast;
