import React from 'react';

import { Image } from '@mui/icons-material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import { SectionWrapper } from 'src/components';

import Badge from '../Badge/Badge';
import Label from '../Label/Label';
import RfqDraftCard from '../RfqDraftCard/RfqDraftCard';
import { RFQ } from './props';

const RfqDisplay = ({
  isListView,
  setIsRfqsEmpty,
  rfqs,
  redirectToRfq
}: {
  isListView: boolean;
  setIsRfqsEmpty: Function;
  rfqs: RFQ[];
  redirectToRfq: (rfqId: number) => void;
}) => {
  return (
    <>
      {rfqs.length > 0 && !isListView && (
        <>
          <SectionWrapper testId="rfqs-in-draft-status">
            <h3 className="mb-4 grid gap-4 w-full max-w-6xl mx-auto">
              <span className="text-md text-black font-500">Drafts</span>
            </h3>

            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full max-w-6xl mx-auto">
              {rfqs.map((rfq) => (
                <RfqDraftCard
                  key={rfq.id}
                  id={rfq.id}
                  image={rfq.image}
                  documentName={rfq.document.name}
                  questionsLength={rfq.questions.length}
                  createdAt={rfq.created_at}
                  redirectToRfq={redirectToRfq}
                />
              ))}
            </div>
          </SectionWrapper>
        </>
      )}

      {rfqs.length > 0 && isListView && (
        <SectionWrapper testId="rfqs-in-draft-status">
          <h3 className="mb-4 grid gap-4 w-full max-w-6xl mx-auto">
            <span className="text-md text-black font-500">Drafts</span>
          </h3>
          <table className="flex flex-col bg-white w-full min-w-[60rem] max-w-6xl mx-auto rounded-xl shadow-md overflow-hidden">
            <thead>
              <tr
                className="flex gap-4 items-start justify-start text-left min-w-full p-4"
                style={{
                  borderBottom: '1px solid var(--mantine-color-cavela-shadow-2)'
                }}
              >
                <th className="min-w-80 uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
                  Quote request name
                </th>
                <th className="w-24 max-w-[25%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
                  Image
                </th>
                <th className="min-w-[15%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
                  Progress
                </th>
                <th className="w-40 max-w-[30%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)] text-center">
                  Created Date
                </th>
                <th className="min-w-40 max-w-[25%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)] ml-auto">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {rfqs.map((rfq) => (
                <tr
                  className="flex gap-4 items-center justify-start bg-white min-w-full p-3 overflow-x-auto cursor-pointer hover:bg-gray-100"
                  onClick={() => redirectToRfq(rfq.id)}
                >
                  <td className="flex items-center min-w-80 max-w-80">
                    <button
                      onClick={() => redirectToRfq(rfq.id)}
                      className="truncate"
                    >
                      <span className="font-500 text-black">
                        {rfq.document.name}
                      </span>
                    </button>
                  </td>
                  <td className="flex items-center min-w-24 max-w-24">
                    <div className="relative flex items-center size-16 rounded-lg bg-border-light-gray-2 justify-center shrink-0">
                      {rfq.image ? (
                        <img
                          src={rfq.image}
                          alt="rfq"
                          className="object-cover rounded-xl"
                        />
                      ) : (
                        <Image
                          htmlColor="#6E747D"
                          fontSize="large"
                          style={{ width: '60%', height: '60%' }}
                        />
                      )}
                    </div>
                  </td>
                  <td className="flex items-center min-w-[15%] max-w-[15%]">
                    <span className="text-black">
                      {rfq.questions.length} answers
                    </span>
                  </td>
                  <td className="flex items-center justify-center text-center w-40 max-w-[30%]">
                    <span className="text-black">
                      {new Date(rfq.created_at).toLocaleDateString('es-ES', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </span>
                  </td>
                  <td className="flex items-center min-w-40 max-w-[25%] ml-auto">
                    <Label variant="draft">
                      <EditRoundedIcon
                        style={{
                          fontSize: '12px'
                        }}
                      />
                      <span>Draft</span>
                    </Label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </SectionWrapper>
      )}
    </>
  );
};

export default RfqDisplay;
