import { useCallback, useContext, useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import AuthContext from '../context/Auth';
import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { MappedUpdate } from '../types/UpdateTypes';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { sortByLuxonDate } from '../utils/sortDates';
import updatesMapper from '../utils/updatesMappers';

const DEFAULT_TAB = 'Overview';

interface SupplierUpdates {
  [key: string]: MappedUpdate[];
}

export interface updatesGroupByDate {
  date: string;
  updates: MappedUpdate[];
}

export default function useUpdates({
  fetchOnLoad = false,
  productId
}: {
  fetchOnLoad?: boolean;
  productId: string;
}) {
  const { userInfo } = useContext(AuthContext);
  const [updates, setUpdatesData] = useState<updatesGroupByDate[]>([]);
  const [mappedUpdates, setMappedUpdates] = useState<SupplierUpdates>({});
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState(0);

  const fetchUpdates = useCallback(async () => {
    if (!userInfo) return;

    setLoading(true);

    try {
      const url = `${API_URL}/api/users/${userInfo.id}/updates?${new URLSearchParams(
        {
          product_id: productId
        }
      )}`;

      const response = await fetchWithAuth(url);

      if (response.status !== 200) {
        setErrorCode(response.status);

        return;
      }

      const data = await response.json();

      const mappedUpdates = updatesMapper(data);
      const groupedUpdates = groupUpdatesByDate(mappedUpdates);
      const sortedUpdates = sortUpdates(groupedUpdates);

      setUpdatesData(sortedUpdates);
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });
    } finally {
      setLoading(false);
    }
  }, [userInfo, productId]);

  useEffect(() => {
    if (fetchOnLoad) fetchUpdates();
  }, [fetchUpdates, fetchOnLoad]);

  useEffect(() => {
    updates && mapUpdates();
  }, [updates]);

  const groupUpdatesByDate = (
    notGroupedUpdates: MappedUpdate[]
  ): updatesGroupByDate[] => {
    const updatesByDate: { date: string; updates: MappedUpdate[] }[] = [];

    for (const update of notGroupedUpdates) {
      // Removes time from date to be able to group by date
      const date = DateTime.fromISO(update.createdAt).toISODate() || '';
      const existingEntry = updatesByDate.find((entry) => entry.date === date);
      if (existingEntry) {
        existingEntry.updates.push(update);
      } else {
        updatesByDate.push({ date, updates: [update] });
      }
    }

    return updatesByDate;
  };

  const sortUpdates = (groupedUpdates: updatesGroupByDate[]) => {
    return groupedUpdates.sort((a, b) => sortByLuxonDate(a.date, b.date));
  };

  const getLatestUpdates = (numOfUpdates: number): updatesGroupByDate[] => {
    return updates.slice(0, numOfUpdates);
  };

  const mapUpdates = () => {
    const updatedSupplierUpdates: SupplierUpdates = {
      [DEFAULT_TAB]: []
    };

    for (const productUpdate of updates) {
      for (const updateItem of productUpdate.updates) {
        if (updateItem?.id) {
          const key: any = updateItem?.supplierCloak;

          if (key) {
            if (!updatedSupplierUpdates[key]) {
              updatedSupplierUpdates[key] = [];
            }

            updatedSupplierUpdates[key].push(updateItem);
          }

          if (
            !updatedSupplierUpdates[DEFAULT_TAB]?.find(
              ({ id = -1 }) => id === updateItem.id
            )
          ) {
            updatedSupplierUpdates[DEFAULT_TAB].push(updateItem);
          }
        }
      }
    }

    setMappedUpdates(updatedSupplierUpdates);
  };

  const markAsResolved = useCallback(
    async (updateId: number) => {
      if (!userInfo) return;

      const url = `${API_URL}/api/users/${userInfo.id}/updates/${updateId}`;

      const response = await fetchWithAuth(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ relevant: false })
      });

      if (response.status !== 200) {
        setErrorCode(response.status);
        return false;
      }

      const data = await response.json();
      const updateToResolve = mappedUpdates[DEFAULT_TAB].find(
        (update) => update.id === updateId
      );
      if (updateToResolve) {
        updateToResolve.relevant = false;
      }
      setMappedUpdates({ ...mappedUpdates });
      return true;
    },
    [mappedUpdates, userInfo]
  );

  return {
    updates,
    mappedUpdates,
    getLatestUpdates,
    loading,
    errorCode,
    fetchUpdates,
    markAsResolved
  };
}
