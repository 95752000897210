export default function Banner() {
  return (
    <div className="py-2 flex items-center gap-2 text-xs sm:text-sm md:text-md bg-p-cavela-blue text-white px-3 sm:px-5 justify-center">
      <div className="shrink-0">
        <img src="/icons/CNFlag.svg" alt="warning" className="w-6 h-6" />
      </div>
      <span className="text-center">
        Chinese New Year celebrations run from Jan 29 to Feb 16. Expect
        slowdowns before, during, and after these dates.
      </span>
    </div>
  );
}
