import { STORAGE_KEY as SELECTED_ORGANIZATION_KEY } from '../hooks/useOrganizationStore';
import useOrganizationStore from '../hooks/useOrganizationStore';
import { BACKEND_URL } from '../settings';
import { failure } from './logger';

const API_URL = BACKEND_URL;

function saveAuthFromUrl(url: string): void {
  const authToken = new URL(url).searchParams.get('auth_token');
  const impersonation = new URL(url).searchParams.get('impersonation');
  const storedAuthToken = getAuthToken();

  // If token is different from the one stored, clear impersonation
  if (
    (storedAuthToken && authToken && storedAuthToken !== authToken) ||
    impersonation === '0'
  ) {
    clearImpersonation();
    clearOrganization();
    window.location.href = '/';
  }

  if (authToken) {
    saveAuthToken(authToken);
  }

  if (impersonation && impersonation !== '0') {
    clearOrganization();
    saveImpersonation(impersonation);
  }
}

const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

const saveAuthToken = (authToken: string) => {
  localStorage.setItem('authToken', authToken);
};

const saveImpersonation = (impersonation: string) => {
  localStorage.setItem('impersonation', impersonation);
};

const getImpersonation = () => {
  return localStorage.getItem('impersonation');
};

const clearImpersonation = () => {
  localStorage.removeItem('impersonation');
};

const clearOrganization = () => {
  useOrganizationStore.getState().setSelectedOrganization(null);
  localStorage.removeItem(SELECTED_ORGANIZATION_KEY);
};

// Function to log in a user
const login = async (username: string, password: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  };

  try {
    const response = await fetch(`${API_URL}/api/token/`, requestOptions);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    if (data.access) {
      // Store user details and jwt token in local storage
      // to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(data));
    }
    return data;
  } catch (error) {
    failure('There was a problem with the fetch operation:', error);
    throw error;
  }
};

// Function to log out a user
const logout = async () => {
  // Remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('authToken');
  localStorage.removeItem('impersonation');
  clearOrganization();
};

const refreshAccessToken = async () => {
  // Assuming the refresh token is stored in local storage
  const userJson = localStorage.getItem('user');
  const user = userJson ? JSON.parse(userJson) : null;
  const refresh = user ? user.refresh : null;

  if (!refresh) {
    throw new Error('No token for refreshing.');
  }

  const response = await fetch(`${API_URL}/api/token/refresh/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh })
  });

  if (!response.ok) {
    throw new Error('Failed to refresh token.');
  }

  const data = await response.json();
  localStorage.setItem(
    'user',
    JSON.stringify({ ...user, accessToken: data.accessToken })
  );
  return data.access;
};

const authService = {
  login,
  logout,
  refreshAccessToken,
  getAuthToken,
  getImpersonation,
  clearImpersonation,
  clearOrganization,
  saveAuthToken,
  saveAuthFromUrl
};

export default authService;
