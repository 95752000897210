import { ReactNode, useEffect, useState } from 'react';

import { Page, SidebarNav } from '..';
import Intercom from '@intercom/messenger-js-sdk';
import useErrorStore from 'src/hooks/useErrorStore';
import useUserInfo from 'src/hooks/useUserInfo';

import { Flex } from '@mantine/core';

import Banner from '../Banner/Banner';
import Toast from '../Toast/Toast';

export default function PageWrapper({
  pageTitle = '',
  loading = false,
  children
}: {
  pageTitle?: string;
  loading?: boolean;
  children: ReactNode;
}) {
  const { error, setError } = useErrorStore();
  const { userInfo } = useUserInfo();
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    if (userInfo) {
      Intercom({
        app_id: 'ouv4eyd2',
        user_id: userInfo.id,
        name: userInfo.username,
        email: userInfo.email
      });
    }
  }, [userInfo]);

  return (
    <div
      className="grid"
      style={{
        height: '100vh',
        gridTemplateRows: showBanner ? 'auto 1fr' : '100vh'
      }}
    >
      {showBanner ? <Banner /> : null}
      <div className="h-full w-full flex grow-0">
        <SidebarNav />
        <Page
          title={pageTitle}
          loading={loading}
          className="max-w-[1200px] mx-auto"
        >
          {children}
        </Page>
        {error ? (
          <Toast message={error} type="error" setError={setError} />
        ) : null}
      </div>
    </div>
  );
}
