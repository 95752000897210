import { create } from 'zustand';

import { Organization } from '../types/Organization';

export const STORAGE_KEY = 'selectedOrganization';

interface OrganizationStore {
  selectedOrganization: Organization | null;
  setSelectedOrganization: (org: Organization | null) => void;
  onOrganizationChange?: () => void;
  setOnOrganizationChange: (callback: () => void) => void;
}

const useOrganizationStore = create<OrganizationStore>((set, get) => ({
  selectedOrganization: JSON.parse(localStorage.getItem(STORAGE_KEY) || 'null'),
  onOrganizationChange: undefined,
  setOnOrganizationChange: (callback) =>
    set({ onOrganizationChange: callback }),
  setSelectedOrganization: (org) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(org));
    set({ selectedOrganization: org });
    get().onOrganizationChange?.();
  }
}));

export default useOrganizationStore;
